.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
}
body {
  font: 16px Helvetica, Sans-Serif;
  line-height: 24px;
  background: url(images/noise.jpg);
}
.clear {
  clear: both;
}
#page-wrap {
  max-width: 800px;
  margin: 40px auto 60px;
  padding: 20px;
}
#pic {
  float: right;
  margin: -30px 0 0 0;
}
h1 {
  margin: 0 0 16px 0;
  padding: 0 0 16px 0;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: -2px;
  border-bottom: 1px solid #999;
}
h2 {
  font-size: 20px;
  margin: 0 0 6px 0;
  position: relative;
}
.experience-position {
  font-style: italic;
  font-family: Georgia, Serif;
  font-size: 16px;
  color: #999;
  font-weight: normal;
}
p {
  margin: 0 0 16px 0;
}
a {
  color: #999;
  text-decoration: none;
  border-bottom: 1px dotted #999;
}
a:hover {
  border-bottom-style: solid;
  color: black;
}
ul {
  margin: 0 0 32px 17px;
}
#objective {
  /* width: 500px; */
  float: left;
}
#objective p {
  font-family: Georgia, Serif;
  font-style: italic;
  color: #666;
}
dt {
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  text-align: right;
  padding: 0 26px 0 0;
  width: 150px;
  float: left;
  height: 100px;
  border-right: 1px solid #999;
}
dd {
  /* width: 600px; */
  float: right;
}
dd.clear {
  float: none;
  margin: 0;
  height: 15px;
}

a.reverse {
  unicode-bidi: bidi-override;
  direction: rtl;
}